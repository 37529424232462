import React, {useState} from 'react';
import styles from "./styles.module.scss";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    IGetOrdersParams,
    IGetOrdersResponse, IOrderError
} from "../../ts/interfaces/Orders/interface";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import {ReactComponent as Download} from '../../assets/icons/download.svg';
import {ReactComponent as Info} from '../../assets/icons/three-squares-svgrepo-com.svg';
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {getOrders, serializeArray} from "../../services/services";
import {useQuery} from 'react-query';
import Pagination from "../../components/Pagination/Pagination";
import {getToken} from "../../services/auth";
import {EnumOrderStatus} from "../../helper/Orders/Enum";
import BarLoader from "react-spinners/BarLoader";
import ModalOrderError from "../../components/ModalOrderError/ModalOrderError";
import {useNavigate} from "react-router-dom";

const Orders: React.FC = () => {
    document.title = 'Market Eduqi | Pedidos'

    const navigate = useNavigate();

    const [filter, setFilter] = useState<IGetOrdersParams>({
        page: 1,
        limit: 50,
        sku: '',
        marketplace_id: '',
        status: '',
        system_id: '',
        error: '',
    });

    const [filterParams, setFilterParams] = useState<IGetOrdersParams>({
        page: 1,
        limit: 50,
        sku: '',
        marketplace_id: '',
        status: '',
        system_id: '',
        error: '',
    });

    const [openModalError, setOpenModalError] = useState<boolean>(false);
    const [orderError, setOrderError] = useState<IOrderError | null>(null);

    const {data, isLoading} = useQuery<IGetOrdersResponse>(['getOrders', filter], () => getOrders(filter));

    const handleDownloadCSV = () => {
        let link = document.getElementById('download_csv');
        link?.click();
    };

    function handleFilter() {
        setFilter({
            ...filter,
            sku: filterParams.sku,
            marketplace_id: filterParams.marketplace_id,
            status: filterParams.status,
            system_id: filterParams.system_id,
            error: filterParams.error,
            page: 1
        });
    }

    const toggleModal = (orderError: IOrderError | null = null) => {
        setOpenModalError(!openModalError);
        setOrderError(orderError);
    }

    return (
        <div className={styles.container}>
            <h1>Pedidos</h1>

            <Formik
                initialValues={{name: '', sku: ''}}
                onSubmit={() => {

                }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="sku"
                            type="text"
                            placeholder="SKU"
                            value={filterParams.sku}
                            className={styles.input}
                            onChange={(e: any) => setFilterParams({...filterParams, sku: e.target.value})}
                            disabled={isLoading}
                        />
                        <Field
                            name="marketplace_id"
                            type="text"
                            placeholder="Nº Marketplace"
                            value={filterParams.marketplace_id}
                            className={styles.input}
                            onChange={(e: any) => setFilterParams({...filterParams, marketplace_id: e.target.value})}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="sku" component="div" className={styles.error}/>
                        <Select
                            value={filterParams.system_id ?? ''}
                            onChange={(e: any) => setFilterParams({...filterParams, system_id: e.target.value})}
                            disabled={isLoading}
                        >
                            <option value="">Loja</option>
                            <option value="1">Nova Concursos</option>
                            <option value="2">Editora Solução</option>
                            <option value="3">Apostilas Opção</option>
                        </Select>
                        <Select
                            value={filterParams.status ?? ''}
                            onChange={(e: any) => setFilterParams({...filterParams, status: e.target.value})}
                            disabled={isLoading}
                        >
                            <option value="">Status</option>
                            {EnumOrderStatus.map((status) => {
                                return (
                                    <option value={status.value}>{status.text}</option>
                                );
                            })}
                        </Select>
                        <Select
                            value={filterParams.error ?? ''}
                            onChange={(e: any) => setFilterParams({...filterParams, error: e.target.value})}
                            disabled={isLoading}
                        >
                            <option value="">Erro</option>
                            <option value="1">Com erro de Nota Fiscal</option>
                            <option value="2">Com erro de Marketplace</option>
                            <option value="0">Sem erro</option>
                        </Select>
                    </div>
                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            onClick={() => handleFilter()}
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download/>}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >

                    CSV
                </Button>
            </div>
            <div className={styles.dNone}>
                <a id="download_csv" rel="noopener noreferrer"
                   href={process.env.REACT_APP_API_URL + "/v1/orders/export?" + serializeArray(filter) + '&token=' + getToken()}
                   target="_blank" download></a>
            </div>
            <div className={styles.totalProducts}>
                <span>Total: {data && data.data ? data.data.total : '-'}</span>
            </div>

            <div className={styles.tableProducts}>
                {isLoading ?
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#510071"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <>
                        {data && data.data.orders.length > 0 ?
                            <table>
                                <thead>
                                <tr>
                                    <th>N Pedido</th>
                                    <th>N Mkt</th>
                                    <th>Nome Entrega</th>
                                    <th>Nome NFE</th>
                                    <th>Skus</th>
                                    <th>Status</th>
                                    <th>Id AnyMarket</th>
                                    <th>Loja</th>
                                    <th>Total</th>
                                    <th>Dt Pagamento</th>
                                    <th>Dt Pedido</th>
                                    <th>Erro</th>
                                    <th>Erro Origem</th>
                                    <th>Timeline</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.orders.map((order) => (
                                    <tr key={order.id}>
                                        <td>{order.increment_id}</td>
                                        <td>{order.marketplace_id}</td>
                                        <td>{order.receiver_name}</td>
                                        <td>{order.buyer_name}</td>
                                        <td>{order.skus}</td>
                                        <td>{order.status}</td>
                                        <td>{order.external_id}</td>
                                        <td>{order.system}</td>
                                        <td>{order.total}</td>
                                        <td>{order.payment_date}</td>
                                        <td>{order.created_at}</td>
                                        <td>{order.error_data &&
                                            <div className={styles.errorData}
                                                 onClick={() => toggleModal(order.error_data)}>
                                                <Warning/>
                                            </div>
                                        }</td>
                                        <td>{order.error_data ? order.error_data.type : ""}</td>
                                        <td>
                                            <div className={styles.linkAnymarket}>
                                                <a href={`/orders/timeline/${order.id}`} target="_blank" rel="noopener noreferrer">Timeline</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.linkAnymarket}>
                                                <a target="_blank"
                                                   href={process.env.REACT_APP_ANYMARKET_URL + '/#/orders/edit/' + order.external_id}>
                                                    <Info/> Detalhes
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <div className={styles.alignCenter}><span>Nenhum produto encontrado.</span></div>
                            )}
                    </>
                }
            </div>
            {data &&
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) => setFilter({...filter, page: payload})}
                />
            }

            {openModalError && orderError &&
                <ModalOrderError toggle={toggleModal} open={openModalError} orderError={orderError}/>
            }

        </div>
    );
};

export default Orders;
