import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import styles from "./styles.module.scss";
import BarLoader from "react-spinners/BarLoader";
import {updateCity, getCity, getOrderEvents, resendOrder} from "../../../services/services";
import logoAnymarket from "../../../assets/icons/logo-anymarket.png";
import logoOmie from "../../../assets/icons/logo-omie.png";
import logoEduqi from "../../../assets/icons/logo-eduqi.png";
import iconError from "../../../assets/icons/error.svg";
import Button from "../../../components/Button/Button";
import CustomModal from "../../../components/CustomModal/CustomModal";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ReactComponent as Copy} from "../../../assets/icons/copy.svg"
import {ReactComponent as CopyChecked} from "../../../assets/icons/copy-check.svg"


const OrderTimeline: React.FC = () => {
    document.title = 'Market Eduqi | Linha do Tempo';

    const {id} = useParams<{ id: string }>();

    const {
        data: timelineData,
        isLoading,
        error,
        refetch: refetchTimeLine
    } = useQuery(['orderEvents', id], () => getOrderEvents(id!), {
        enabled: !!id,
    });


    const [open, setOpen] = useState(false);
    const [resendOpen, setResendOpen] = useState(false);
    const [cityData, setCityData] = useState({lastCity: '', newCity: ''});
    const [orderEventId, setOrderEventId] = useState<number | null>(null);
    const [copy, setCopy] = useState(false);

    const {data: city, refetch} = useQuery<{
        data: { city: string }
    }>(['getCity', id], () => getCity(Number(id) ?? 0), {
        enabled: !!id,
    });

    useEffect(() => {
        if (city?.data) {
            setCityData({lastCity: city.data.city, newCity: city.data.city});
        }
    }, [city]);

    const handleUpdateCity = async () => {
        try {
            const data = await updateCity({
                order_id: id!,
                last_city: cityData.lastCity,
                new_city: cityData.newCity,
            });
            toast.success(data.message);

            await refetch();
            setOpen(false);
        } catch (error) {
            console.error('Erro ao atualizar a cidade:', error);
            toast.error('Erro ao atualizar a cidade.');
        }
    }

    const handleResendProduct = async () => {
        try {
            const data = await resendOrder({orderId: Number(id), orderEventId: Number(orderEventId)});
            data.data?.success ? toast.success(data.data.message) : toast.error(data.data.message);
            await refetchTimeLine();
            setResendOpen(false);
        } catch (error) {
            console.error('Erro ao reenviar o produto:', error);
            toast.error('Erro ao reenviar o produto.');
        }
    }

    if (error) {
        return <div className={styles.error}>Erro ao carregar os eventos.</div>;
    }

    const getImageByType = (type: string): string | undefined => {
        switch (type) {
            case 'ANY_MARKET':
                return logoAnymarket;
            case 'OMIE':
                return logoOmie;
            case 'EDUQI':
                return logoEduqi;
            case 'EXPEDICAO':
                return logoEduqi;
            default:
                return undefined;
        }
    };

    function handleCopy() {
        setCopy(true);
        navigator.clipboard.writeText(timelineData?.marketplace_id);
        setTimeout(() => {
            setCopy(false)
        }, 2000)
    }

    return (
        <div className={styles.container}>
            <h1>
                <button onClick={handleCopy}>{copy ? <CopyChecked className={styles.checked} /> : <Copy />}</button>
                {timelineData?.marketplace_id} - Linha do Tempo do Pedido: {id}</h1>
            <div className={styles.searchProducts}>
                {isLoading ?
                    <div className={styles.loading}>
                        <BarLoader color="#510071"/>
                        <p>Carregando timeline...</p>
                    </div>
                    :
                    <>
                        {timelineData && timelineData.events.length > 0 ?
                            <div className={styles.timeline}>
                                {timelineData.events.map((event: any, index: number) => (

                                    <div
                                        key={index}
                                        className={`${styles.timelineItem} ${event.external ? styles.right : styles.left} ${event.error ? styles.error : ''}`}
                                    >
                                        <div className={styles.content}>
                                            <div className={styles.groupTitle}>
                                                <h3>{event.title}</h3>
                                                {getImageByType(event.type) && (
                                                    <span
                                                        className={`${styles.systemImage} ${event.error ? styles.error : ''}`}>
                                                <img
                                                    src={getImageByType(event.type)}
                                                    alt={event.type}
                                                />
                                            </span>
                                                )}
                                            </div>
                                            <span className={styles.date}>{event.date}</span>
                                            <div className={styles.errorEvent}>
                                                <p>{event.description}</p>
                                                <div className={styles.groupActions}>
                                                    {event.error && event.type === 'OMIE' &&
                                                        <Button
                                                            type="button"
                                                            width="120px"
                                                            height="39px"
                                                            theme="close"
                                                            fontSize="14px"
                                                            onClick={() => {
                                                                setResendOpen(true);
                                                                setOrderEventId(event.id)
                                                            }}
                                                            disabled={isLoading}
                                                        >
                                                            Reenviar
                                                        </Button>
                                                    }
                                                    {event.error && event.type === 'OMIE' &&
                                                        <Button
                                                            type="button"
                                                            width="120px"
                                                            height="39px"
                                                            theme="primary"
                                                            fontSize="14px"
                                                            onClick={() => setOpen(true)}
                                                            disabled={isLoading}
                                                        >
                                                            Alterar cidade
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                            {event.error && event.errorData && (
                                                <div>
                                                    <div className={styles.errorMessage}>
                                                        <p>
                                                            <img className={styles.errorIcon} src={iconError}
                                                                 alt={"Erro"}/>
                                                            Este evento contém erros. Verifique os detalhes!
                                                        </p>

                                                    </div>
                                                    {event.errorData?.message && (
                                                        <p>
                                                            <b>Mensagem de erro:</b> {event.errorData?.message}
                                                        </p>
                                                    )}

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className={styles.loading}>
                                <p>Nenhum evento encontrado.</p>
                            </div>
                        }
                    </>
                }
            </div>

            {open && (
                <CustomModal
                    open={open}
                    setOpen={setOpen}
                    title="Editar Cidade"
                    onSubmit={handleUpdateCity}
                >
                    <label>
                        Cidade Atual:
                        <input type="text" value={cityData.lastCity} disabled/>
                    </label>
                    <label>
                        Nova Cidade:
                        <input
                            type="text"
                            value={cityData.newCity}
                            onChange={(e) => setCityData({...cityData, newCity: e.target.value})}
                        />
                    </label>
                </CustomModal>
            )}

            {resendOpen && (
                <CustomModal
                    open={resendOpen}
                    setOpen={setResendOpen}
                    title=""
                    onSubmit={handleResendProduct}
                >
                    <h5>Tem certeza que deseja reenviar o pedido?</h5>
                    <p>Se você reenviar o pedido, o mesmo será enviado novamente para o sistema Omie.</p>
                </CustomModal>
            )}
            <ToastContainer/>
        </div>
    );
};

export default OrderTimeline;
