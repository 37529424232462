import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styles from './styles.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

interface CustomModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    children: React.ReactNode;
    onSubmit: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ open, setOpen, title, children, onSubmit }) => {
    const toggle = () => setOpen(false);

    return (
        <Modal isOpen={open} toggle={toggle} className={styles.modalContent}>
            <ModalBody className={styles.modalBody}>
                <span className={styles.close} onClick={toggle}>
                    &times;
                </span>
                <h2>{title}</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    {children}
                    <div className={styles.btnConfirm}>
                        <button
                            type="button"
                            onClick={toggle}
                            className="btn btn-secondary"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Salvar
                        </button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default CustomModal;